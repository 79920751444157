.loader_span {
    height: 40px;
    aspect-ratio: 1/3;
    background-color: rgb(55, 48, 163);
    display: block;
    border-radius: 5rem;
    transform: scaleY(1);
    animation: loader_anm 0.9s infinite alternate;
}

.loader_span_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center477;
}

.loader_span_div>div {
    display: flex;
    gap: 0.75rem;
}

.loader_span:nth-child(2) {
    animation-delay: 0.3s !important;
}

.loader_span:nth-child(3) {
    animation-delay: 0.6s;
}

.loader_img {
    width: auto;
    height: 40px;
    margin-bottom: 1rem;
}

@keyframes loader_anm {

    to {
        transform: scaleY(0.5);
    }
}